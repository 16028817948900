import Dexie from 'dexie'

const db = new Dexie('andro-certo')

db.version(1).stores({
  farm: 'code, name, owner, city, created, changed',
  survey: 'code, farm, name, birth, register, race, created, changed, active'
})

export default db
